<template>
  <div v-if="paramDark">
    <q-btn round push size="12px" color="white" @click="mudarTema">
      <q-tooltip class="bg-primary">
        {{ darkIsActive ? "Desativar" : "Ativar" }} modo noturno
      </q-tooltip>
      <q-icon
        :name="darkIsActive ? 'lightbulb' : 'mode_night'"
        color="primary"
      />
    </q-btn>
  </div>
</template>

<script>
import { Dark } from "quasar";
import ParametrosService from "@/services/parametros";
import store from "@/store/index";
import { setCssVar } from "quasar";

export default {
  name: "QBotaoTema",

  data: () => ({
    paramDark: false,
  }),

  created() {
    this.getParametro();
  },

  methods: {
    mudarTema() {
      Dark.toggle();
      store.commit("login", {
        ...store.getters.getUsuarioLogado,
        modo_noturno: Dark.isActive,
      });
      this.atualizaDarkThemeGang();
    },

    getParametro() {
      ParametrosService.getParametro("TEMA_ESCURO")
        .then(({ data }) => {
          this.paramDark = Boolean(Number(data[0].valor));
        })
        .catch((e) =>
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Erro ao buscar um parametro",
            error: e,
          })
        );
    },

    atualizaDarkThemeGang() {
      if (process.env.VUE_APP_EMPRESA.toLowerCase() === "gang") {
        setCssVar("primary", Dark.isActive ? "#0055E1" : "#001432");
        setCssVar("secondary", Dark.isActive ? "#001432" : "#0055E1");
        setCssVar("accent", "#F89E41");
      }
    },
  },

  computed: {
    darkIsActive() {
      return Dark.isActive;
    },
  },
};
</script>

<style lang="scss">
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 24px;
}
</style>
